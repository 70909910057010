@import 'src/design-system/styles/layout-variables.scss';
@import 'src/design-system/styles/typography.mixins';

.container {
  display: flex;
  flex-direction: column;
  padding: $content-indent;
  height: 100%;

  & .headerWrapper {
    display: flex;
    align-items: baseline;
    justify-content: left;

    & > button > span {
      margin: auto;
    }
  }

  & .title {
    margin: 0 0 16px 0;
    padding: 0;

    @include font-style-h4($font-weight: $font-weight-regular);
    font-family: inherit;

    color: var(--text-10);
  }
}

.formContainer {
  display: flex;
  justify-content: flex-end;

  margin-bottom: 16px;
  z-index: 999;
}
