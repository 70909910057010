.datetime {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.sum {
  margin: 0;
}

.transactionDeposit {
  grid-area: type;
  color: var(--system-success);
}

.noAmount {
  padding-left: 5px;
}

.cashback {
  cursor: pointer;
}
