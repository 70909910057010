@import 'src/design-system/styles/typography.mixins';

.body {
  padding-top: 0;
  padding-bottom: 20px;
}

.contentWrapper {
  @include list-styles-reset;

  display: flex;
  flex-direction: column;
  gap: 8px;

  & li {
    @include font-style-small;
  }
}

.label {
  color: var(--text-20);
}

.value {
  display: block;
  margin: 0;
}
