.root {
  width: 100%;
}

.content {
  padding: 16px 24px;
}

.table {
  width: 100%;
  font-size: 14px;
}

thead {
  border-bottom: 1px solid var(--shape-50);

  & th {
    text-align: left;
  }
}

tr:not(:last-of-type) {
  border-bottom: 1px solid var(--shape-50);
}

th,
td {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 10px 8px 0;
}
