@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  & p {
    margin: 0;
  }
}

.calcButton {
  @include font-style-small;
  @include button-styles-reset;

  display: flex;
  align-items: center;
  row-gap: 8px;
  width: max-content;
  line-height: 24px;
  color: var(--text-link);
  padding: 0 !important;

  &:hover {
    background: transparent;
    color: var(--text-primary);
    padding: 0 !important;
  }
}

.spinnerWrapper {
  height: 24px;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  & p {
    color: var(--text-40);
  }
}

.failureText {
  color: var(--system-error);
}

.description {
  @include font-style-tiny;

  display: block;
  color: var(--text-20);
}
